import React from "react";

import { useTranslation } from 'react-i18next'

import { FAQParagraph, ContactInfo } from "../components/FAQSection";

import { H1, H2 } from '../components/common'
import Accordion from '../components/Accordion'

import "../css/Faq.scss";

function FAQ() {
  const [t, _] = useTranslation("FAQ");
  const categories = t('categories', { returnObjects: true });

  return (
    <div className="faq_body" style={{}}>
      <H1
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '64px',
        }}
      >FAQ</H1>

      {/* Titles */}
      {categories.map((category, idx) => (
        <div className="accordion-group" key={idx}
          style={{
            marginBottom: '64px',
          }}
        >
          <H2>{category.title}</H2>
          {/* Questions */}
          {category.qna.map((item, idx) => (
            <Accordion key={idx}>
              <Accordion.Summary>
                {item.q}
              </Accordion.Summary>
              <Accordion.Details>
                {item.a}
              </Accordion.Details>
            </Accordion>
          ))}
        </div>
      ))}

      {/*
      <FAQParagraph />
      */}
      <ContactInfo />
    </div>
  );
}

export default FAQ;
