import React, { Suspense } from 'react';
import { useTranslation, Trans } from "react-i18next";
import Loading from "../pages/LoadingPage";
import "../css/TermPage.css"

function Term() {
    const {t, i18n, ready} = useTranslation("terms", {useSuspense: false});
    const terms = t("terms_of_service.terms", { returnObjects: true });

    if (!Array.isArray(terms)) {
        return <></>;
    }

    return(
        <Suspense fallback={Loading}>
            <div className={"term-text"}
                style={{paddingLeft: 100, padddingRight: 100, whiteSpace: "pre-line"}}>
                <br/>
                <h1>{t("terms_of_service.title")}</h1>
                <p>{t("terms_of_service.description")}</p>
                <br/>

                {
                    terms.map((term, key) => (
                        <>
                            <h2>{term.title}</h2>
                            <ol>
                                {
                                    term.contents.map((content, index) => (
                                        <>
                                            {
                                                Array.isArray(content) ?
                                                    <ol>
                                                        {
                                                            content.map((subterm, subindex) => (
                                                                <p key={subindex}>
                                                                    <li>{subterm}</li>
                                                                </p>
                                                            ))
                                                        }
                                                    </ol>
                                                    : <p>
                                                        <li key={index}>
                                                            {content}
                                                        </li>
                                                    </p>
                                            }
                                        </>
                                    ))
                                }
                            </ol>
                            <br/>
                        </>
                    ))
                }
            </div>
        </Suspense>
    );
}

export default Term;