import React from 'react'

import './BaseModal.scss'

interface BaseModalProps {
  open: boolean;
  onDismiss: Function | undefined;
  children: React.ReactNode;
}

const BaseModal = (props: BaseModalProps) => {
  return (
    <div className="base-modal"
      style={{
        display: props.open ? 'block' : 'none',
      }}
    >
      <div className="base-modal__background"
        onClick={() => {
          if (props.onDismiss !== undefined) {
            props.onDismiss();
          }
        }}
      ></div>
      <div className="base-modal__body">
        {props.children}
      </div>
    </div>
  );
}

export default BaseModal
