import React, { Suspense } from 'react';
import { Trans, useTranslation } from "react-i18next";
import Loading from "../pages/LoadingPage";
import "../css/PolicyPage.css"

function Policy() {
    const [t, i18n, ready] = useTranslation('policy', {useSuspense: false});
    const articles = t("articles", { returnObjects: true });

    if (!Array.isArray(articles)) {
        return <></>;
    }

    return(
        <Suspense fallback={Loading} >
            <div className={"policy-text"}
                style={{
                margin: "50px",
                display: "grid", gridRowGap: "20px"}}>

                <h1>{t("title")}</h1>
                <div>
                    <header>
                        {t("description")}
                    </header>
                    <b
                        style={{float: "left",
                            fontWeight: "normal",
                            marginTop: "10px",
                            marginBottom: "10px",
                            marginLeft: "50px",
                            paddingLeft: "15px",
                            borderLeft: "3px solid #666666"}}>
                        <strong>{t("note")}</strong>
                    </b>
                </div>
                {
                    articles.map((article, key) => (
                        <>
                            <h2>{article.title}</h2>
                            <h3>
                                <ol>
                                    {
                                        article.contents.map((sub, subkey) => (
                                            <li key={subkey}>
                                                <>
                                                    <h5>{sub.subtitle}</h5>
                                                    <ol>
                                                        {
                                                            sub.subcontents.map((content, index) => (
                                                                <li key={index}>
                                                                    <h5>{content.title}</h5>
                                                                    <h6>
                                                                        <ol>
                                                                            {
                                                                                content.contents.map((item, idx) => (
                                                                                    <li key={idx}>
                                                                                        <p>{item}</p>
                                                                                    </li>
                                                                                ))
                                                                            }
                                                                        </ol>
                                                                    </h6>
                                                                </li>
                                                            ))
                                                        }
                                                    </ol>
                                                </>
                                            </li>
                                        ))
                                    }
                                </ol>
                            </h3>
                        </>
                    ))
                }
            </div>
        </Suspense>
    );
}


export default Policy;