import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import PropTypes from "prop-types";
import axios from "axios";
import {
  CardBox,
  InquiryBox,
  InquiryCol,
  InquiryRow,
  Intro,
  StyledButton,
  StyledCard,
  Subtitle,
  ContainerBox,
  ContainerInnerBox,
  HeadTitle,
  NotiBox,
  ContactBox,
  ButtonBox,
} from "../styles/SupportSectionStyle";

const Card = ({ imgsrc, title, text }) => {
  return (
    <StyledCard>
      <img src={imgsrc} />
      <h3>{title}</h3>
      <p>{text}</p>
    </StyledCard>
  );
};

Card.propTypes = {
  imgsrc: PropTypes.any.isRequired,
  title: PropTypes.any.isRequired,
  text: PropTypes.any.isRequired,
};

class Cards extends React.Component {
  render() {
    return (
      <CardBox>
        <Card
          imgsrc={require("../assets/FeatureSection/fast.svg").default}
          title="Getting started"
          text="Synchronized in real-time
                                    across all users. No spinners or waiting."
        />
        <Card
          imgsrc={require("../assets/FeatureSection/fast.svg").default}
          title="How to install"
          text="Synchronized in real-time
                                    across all users. No spinners or waiting."
        />
        <Card
          imgsrc={require("../assets/FeatureSection/fast.svg").default}
          title="Refund"
          text="Synchronized in real-time across all users. No spinners or waiting."
        />
        <Card
          imgsrc={require("../assets/FeatureSection/fast.svg").default}
          title="Bulk image"
          text="Synchronized in real-time across all users. No spinners or waiting."
        />
        <Card
          imgsrc={require("../assets/FeatureSection/fast.svg").default}
          title="Support OS"
          text="Synchronized in real-time across all users. No spinners or waiting."
        />
        <Card
          imgsrc={require("../assets/FeatureSection/fast.svg").default}
          title="Buy dump"
          text="Synchronized in real-time across all users. No spinners or waiting."
        />
      </CardBox>
    );
  }
}

class IntroSection extends React.Component {
  render() {
    return (
      <Intro>
        <div>
          <div className={"slogan-header"}>
            <h1 className={"slogan-h1-style1"}>Help Center</h1>
            <h1 className={"slogan-h1-style2"}>We always ready to help you!</h1>
          </div>
          <div className={"slogan-sub-paragraph"}>
            <p>Are you looking for this?</p>
          </div>
          <Subtitle>Popular Question</Subtitle>
          <Cards />
        </div>
      </Intro>
    );
  }
}

class Inquiry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      fields: { lname: "", email: "" },
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      name: this.state.name,
      user_mail: this.state.email,
      subject: this.state.subject,
      description: this.state.description,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // TODO : Change hostname
    // When REST return is {"result":"jun","return":0},
    // set to response.data.result and response.data.return
    axios
      .post(`${process.env.REACT_APP_API_ROOT}/cs_mail`, data, config)
      .then((response) => {
        console.log(response.data.result);
        alert(response.data.result);
      })
      .catch((error) => {
        alert("전송에 실패하였습니다." + data);
      });
  };

  render() {
    return (
      <InquiryBox>
        <Subtitle>Hello! How can i help you?</Subtitle>
        <form onSubmit={this.handleSubmit}>
          <InquiryCol>
            <InquiryRow>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Your name.."
                onChange={this.handleChange}
              />
            </InquiryRow>
            <InquiryRow>
              <label htmlFor="email">Email</label>
              <input
                id="email"
                name="email"
                placeholder="Your email address.."
                onChange={this.handleChange}
              />
            </InquiryRow>
          </InquiryCol>

          <InquiryRow>
            <label htmlFor="subject">Subject</label>
            <input
              id="subject"
              name="subject"
              placeholder="Title of inquiry!"
              onChange={this.handleChange}
            />
          </InquiryRow>

          <InquiryRow>
            <label htmlFor="Description">Description</label>
            <textarea
              id="description"
              name="description"
              placeholder="Write something.."
              maxLength="1200"
              onChange={this.handleChange}
            />
          </InquiryRow>
          <StyledButton type="submit" value="Submit" />
        </form>
      </InquiryBox>
    );
  }
}

function SupportMain() {
  const [t, i18n] = useTranslation("default");

  return (
    <ContainerBox>
      <ContainerInnerBox>
        <HeadTitle>{t("support.head_title")}</HeadTitle>
        <NotiBox>
          <h2>{t("support.recommended_spec.title")}</h2>
          {t("support.recommended_spec.content", { returnObjects: true }).map(
            (item) => (
              <p key={item}>{item}</p>
            )
          )}
        </NotiBox>
        <NotiBox>
          <h2>{t("support.faq.title")}</h2>
          <p>{t("support.faq.content")}</p>
        </NotiBox>
        <NotiBox>
          <h2>{t("support.contact_info.title")}</h2>
          <p>{t("support.contact_info.content")}</p>
        </NotiBox>
        <ContactBox>
          <h2>
            <Trans i18nKey={"default:support.contact_us.title"} />
          </h2>
          <ButtonBox>
            <address>
              <a href={t("support.contact_us.mailto")}>
                {t("support.contact_us.btn_txt")}
              </a>
            </address>
          </ButtonBox>
        </ContactBox>
      </ContainerInnerBox>
    </ContainerBox>
  );
}

export {
  // IntroSection,
  // Inquiry
  SupportMain,
};
