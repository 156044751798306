import React from "react";

function Error() {
    return (
        <div style={{display: "grid", justifyContent: "center", justifyItems: "center", gap: "40px"}}>
            <h1 style={{fontSize: "28px"}}>Service Error</h1>
        </div>
    )
}

export default Error;