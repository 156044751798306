import styled, { css } from 'styled-components';


export const ContainerBox = styled.main`
  width: 100%;
  height: 100%;
  margin: 50px 0px 50px 0px;
  background: linear-gradient(180deg, rgba(208, 244, 255, 0) 0%, #a6e4f6 99.36%);
`;

export const ContainerInnerBox = styled.div`
  max-width: 1140px;
  margin: 0 auto 100px;
  text-align: center;
`;

export const Headtitle = styled.h1`
  padding: 30px 20px 30px 20px;
  font-family: 'Inter';
  font-style: normal;
  font-size: 36px;
  font-weight: 900;
  color: #00BEF2;
  text-align: center;
`

export const ManualCard = styled.div` {
  display: Grid;
  align-items: center;
  justify-content: center;
  margin: 0 20px 20px 20px;
  padding: 15px 50px;
  background: #ffffff;
  outline-style: solid;
  outline-color: #AAAAAA30; 
  box-shadow: 8px 8px 24px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-family: 'Inter';
  font-style: normal;
}`