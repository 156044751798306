import React from "react";

import {
    IntroSection,
    CatalogueIntro,
    CatalogueFeature
} from '../components/HomeSection';


function Home() {
    return (
        <div id={'container'}>
            <div className={'Home'}>
                <IntroSection/>
                <CatalogueIntro/>
                <CatalogueFeature/>
            </div>
        </div>
    )
}

export default Home