import React from 'react'

import classNames from 'classnames'

import './ProgressBar.scss'

interface ProgressBarProps {
  className: string | undefined;
  percentage: number;
  showText: boolean;
  style: React.CSSProperties;
}

const ProgressBar = (props: ProgressBarProps) => {
  return (
    <div className={classNames('blurtle-progress-bar', props.className)}
      style={props.style}
    >
      <div className="value"
        style={{
          width: `${props.percentage}%`,
        }}
      >
      </div>
      <div className={classNames('text', {
        'text--hidden': !props.showText,
      })}>
        {`${props.percentage}%`}
      </div>
    </div>
  );
}

ProgressBar.defaultProps = {
  showText: false,
};

export default ProgressBar
