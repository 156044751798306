import React from "react";
import {
    OrderInfo
} from '../components/OrderDoneSection';


function OrderDone() {
    return (
        <OrderInfo/>
    )
}

export default OrderDone;
