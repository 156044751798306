import React from "react";
import { OrderComponent } from "../components/OrderSection";
import { useTranslation } from "react-i18next";
import { ContainerBox } from "../styles/OrderStyle";

function Order() {
  const [t, i18n] = useTranslation("default");

  return (
    <>
      <div
          style={{
            alignContent: "center",
            textAlign: "center",
            width: "100vw",
            minHeight: "64px",
            backgroundColor: "#00c7ff",
            // margin: "min(10vw, 100px) 0 0 0",
            marginLeft: "calc(-50vw + 50%)",
            position: "absolute",
            marginTop: "5px",
            left: "0",
          }}
        >
          <h2 style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            {t("order.banner")}
          </h2>
      </div>
      <ContainerBox>
        
        <OrderComponent />
      </ContainerBox>
    </>
  );
}

export default Order;
