import React, { useState, useEffect, useRef } from 'react'

import classNames from 'classnames'

import './Accordion.scss'

//===============
// Summary
//===============
interface SummaryProps {
  className: string | undefined;
  children: React.ReactNode;
}

const Summary = (props: SummaryProps) => {
  return (
    <div className="summary"
    >
      {props.children}
    </div>
  );
}

//================
// Details
//================
interface DetailsProps {
  className: string | undefined;
  children: React.ReactNode;
}

const Details = (props: DetailsProps) => {
  return (
    <div className={classNames('details', props.className)}>
      {props.children}
    </div>
  );
}

interface AccordionProps {
  className: string | undefined;
  summary: React.ReactNode;
  details: React.ReactNode;
  children: [React.ReactElement<SummaryProps>, React.ReactElement<DetailsProps>];
}

const Accordion = (props: AccordionProps) => {
  const detailsRef = useRef<HTMLDivElement | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [detailsHeight, setDetailsHeight] = useState<number>(0);
  const [isRendered, setIsRendered] = useState<boolean>(false);

  useEffect(() => {
    if (detailsRef !== null) {
      setDetailsHeight(detailsRef.current?.offsetHeight as number);
      setIsRendered(true);
    }
  }, []);

  return (
    <div className={classNames('blurtle-accordion', props.className, {
      'blurtle-accordion--init': !isRendered,
    })}>
      <div className="summary-wrapper"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {props.children[0]}
      </div>
      <div className="details-wrapper"
        ref={detailsRef}
        style={{
          height: (() => {
            if (detailsHeight === 0) {
              return 'initial';
            }
            if (detailsHeight > 0 && !isOpen) {
              return '0';
            }
            if (detailsHeight > 0 && isOpen) {
              return detailsHeight;
            }
          })(),
          opacity: isOpen ? '100%' : '0%',
        }}
      >
        {props.children[1]}
      </div>
    </div>
  );
}

Accordion.Summary = Summary;
Accordion.Details = Details;

export default Accordion
