import React from "react";
import PropTypes from 'prop-types';
import {CardBox, FeatureCardBox, Intro, StyledCard} from '../styles/FeatureSectionStyle';


const Card = ({imgsrc, title, text}) => {
    return(
        <StyledCard>
            <div>
                <img src={imgsrc}/>
                <h4>{title}</h4>
                <p>{text}</p>
            </div>
        </StyledCard>
    );
}

Card.propTypes = {
    imgsrc: PropTypes.any.isRequired,
    title: PropTypes.any.isRequired,
    text: PropTypes.any.isRequired
};

class Cards extends React.Component {
    render() {
        return(
            <CardBox>
                <Card imgsrc={require('../assets/FeatureSection/fast.svg').default}
                      title='Built for speed'
                      text='Synchronized in real-time
                                    across all users. No spinners or waiting.' />
                <Card imgsrc={require('../assets/FeatureSection/fast.svg').default}
                      title='Built for speed'
                      text='Synchronized in real-time
                                    across all users. No spinners or waiting.' />
                <Card imgsrc={require('../assets/FeatureSection/fast.svg').default}
                      title='Built for speed'
                      text='Synchronized in real-time across all users. No spinners or waiting.' />
                <Card imgsrc={require('../assets/FeatureSection/fast.svg').default}
                      title='Built for speed'
                      text='Synchronized in real-time across all users. No spinners or waiting.' />
                <Card imgsrc={require('../assets/FeatureSection/fast.svg').default}
                      title='Built for speed'
                      text='Synchronized in real-time across all users. No spinners or waiting.' />
                <Card imgsrc={require('../assets/FeatureSection/fast.svg').default}
                      title='Built for speed'
                      text='Synchronized in real-time across all users. No spinners or waiting.' />
            </CardBox>
        );
    }
}

class IntroSection extends React.Component {
    render() {
        return (
            <Intro>
                <div>
                    <div className={'slogan-header'}>
                        <h1 className={'slogan-h1-style1'}>Feature List</h1>
                        <h1 className={'slogan-h1-style2'}>
                            An experience you’d expect from a<br/>
                            professional tool.
                        </h1>
                    </div>
                    <div className={'slogan-sub-paragraph'}>
                        <p>Opinionated and designed for daily use.</p>
                    </div>
                    <Cards/>
                </div>
            </Intro>
        )
    }
}

class FeaturecardSection extends React.Component {
    render() {
        return (
            <FeatureCardBox>
                <img src={require('../assets/FeatureSection/dummy.svg').default}/>
                <img src={require('../assets/FeatureSection/dummy.svg').default}/>
                <img src={require('../assets/FeatureSection/dummy.svg').default}/>
                <img src={require('../assets/FeatureSection/dummy.svg').default}/>
                <img src={require('../assets/FeatureSection/dummy.svg').default}/>
                <img src={require('../assets/FeatureSection/dummy.svg').default}/>
                <img src={require('../assets/FeatureSection/dummy.svg').default}/>
                <img src={require('../assets/FeatureSection/dummy.svg').default}/>
            </FeatureCardBox>
        )
    }
}


export {
    IntroSection,
    FeaturecardSection
};