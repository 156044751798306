import { useState, useEffect } from 'react';

function useIsWindowsDesktop() {
  const [isWindowsDesktop, setIsWindowsDesktop] = useState(false);

  useEffect(() => {
    const agent = navigator.userAgent.toLowerCase();

    // Windows OS 식별
    const isWindows = agent.includes('windows nt');
    const isWindowsPhone = agent.includes('windows phone');

    // Windows 데스크탑 여부 확인 (Windows Phone 제외)
    setIsWindowsDesktop(isWindows && !isWindowsPhone);
  }, []); // 빈 배열로 의존성 설정

  return isWindowsDesktop;
}

export default useIsWindowsDesktop;
