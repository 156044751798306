import styled, { css } from 'styled-components';

export const Intro = styled.div`
  font-family: 'Inter';
  font-style: normal;
  align-items: center;
`;

export const CardBox = styled.div`
  display: grid;
  font-family: 'Inter';
  font-style: normal;
  align-items: center;
  justify-items: center;
  padding: 50px 200px 50px 200px;
  grid-template-columns: auto auto auto;
  gap: 10px;
`;

export const StyledCard = styled.div`
  align-content: normal;
  padding: 40px 40px 40px 40px;

  max-width: 1000px;
  max-height: 1000px;

  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
`;

export const FeatureCardBox = styled.div`
  display: grid;
  font-family: 'Inter';
  font-style: normal;
  align-items: center;
  justify-items: center;
  padding: 50px 200px 50px 200px;
  grid-template-columns: auto auto;
  gap: 50px;
`;