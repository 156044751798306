import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import Cookies from 'js-cookie';

const cookieLanguage = Cookies.get('i18next');

i18n
    .use(HttpBackend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath: "/locale/{{lng}}/{{ns}}.json",
        },
        allowObjectInHTMLChildren: true,
        react: {
            wait: true,
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ["a", "link", "u", "br", "strong", "i", "sub", "sup", "li"],
        },
    });

export default i18n;