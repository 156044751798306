import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'

import axios from 'axios'

import {
  CodeActivation,
  CommunityVersionDownload,
} from "../components/DownloadSection";
import { ContainerBox } from "../styles/OrderStyle";
import {
  ButtonBox,
  ContainerInnerBox,
  HeadTitle,
} from "../styles/DownloadStyle";
import Typography from "@mui/material/Typography";

import gtag_report_conversion from '../lib/ConversionTracking'

import "../css/DownloadPage.scss";

import { useTranslation } from 'react-i18next'

import { H1 } from '../components/common'
import BaseTable from '../components/BaseTable'
import BaseButton from '../components/BaseButton'
import BaseTextInput from '../components/BaseTextInput'
import BaseModal from '../components/BaseModal'
import ProgressBar from '../components/ProgressBar'
import { set } from "react-ga";

const DownloadTable = (props) => {
  const [t, _] = useTranslation('default');

  const tableContents = t('download.feature_table', { returnObjects: true });

  return (
    <BaseTable className="download-table">
      <BaseTable.Header>
        <BaseTable.Row textAlign="center">
          <BaseTable.HeaderCell></BaseTable.HeaderCell>
          <BaseTable.HeaderCell>{t('download.community_version')}</BaseTable.HeaderCell>
          <BaseTable.HeaderCell>{t('download.pro_version')}</BaseTable.HeaderCell>
        </BaseTable.Row>
      </BaseTable.Header>

      <BaseTable.Body>
        {/* Blur edit feature */}
        <BaseTable.Row textAlign="center">
          <BaseTable.DataCell>{tableContents[0].feature}</BaseTable.DataCell>
          <BaseTable.DataCell>{tableContents[0].community}</BaseTable.DataCell>
          <BaseTable.DataCell>{tableContents[0].pro}</BaseTable.DataCell>
        </BaseTable.Row>

        {/* AI auto blur */}
        <BaseTable.Row textAlign="center">
          <BaseTable.DataCell>{tableContents[1].feature}</BaseTable.DataCell>
          <BaseTable.DataCell>{tableContents[1].community}</BaseTable.DataCell>
          <BaseTable.DataCell>{tableContents[1].pro}</BaseTable.DataCell>
        </BaseTable.Row>

        {/* To file */}
        <BaseTable.Row textAlign="center">
          <BaseTable.DataCell>{tableContents[2].feature}</BaseTable.DataCell>
          <BaseTable.DataCell>{tableContents[2].community}</BaseTable.DataCell>
          <BaseTable.DataCell>{tableContents[2].pro}</BaseTable.DataCell>
        </BaseTable.Row>

        {/* Price */}
        <BaseTable.Row textAlign="center">
          <BaseTable.DataCell>{tableContents[3].feature}</BaseTable.DataCell>
          <BaseTable.DataCell>{tableContents[3].community}</BaseTable.DataCell>
          <BaseTable.DataCell>{tableContents[3].pro}</BaseTable.DataCell>
        </BaseTable.Row>

        {/* Buttons */}
        <BaseTable.Row textAlign="center">
          <BaseTable.DataCell
            style={{
              backgroundColor: 'white',
            }}
          >
          </BaseTable.DataCell>
          <BaseTable.DataCell
            style={{
              backgroundColor: 'white',
            }}
          >
            <BaseButton className="cell-button"
              disabled={props.disabled}
              onClick={() => {
                if (props.onClickDownload !== undefined) {
                  props.onClickDownload();
                }
              }}
              >
              {t('download.btn_download')}
            </BaseButton>
          </BaseTable.DataCell>
          <BaseTable.DataCell
            style={{
              backgroundColor: 'white',
            }}
          >
            <BaseButton className="cell-button"
              disabled={props.disabled}
              onClick={() => {
                if (props.onClickSubmit !== undefined) {
                  props.onClickSubmit();
                }
              }}
              >
              {t('download.btn_txt')}
            </BaseButton>
          </BaseTable.DataCell>
        </BaseTable.Row>
      </BaseTable.Body>
    </BaseTable>
  );
}

const Download = () => {
  const navigate = useNavigate();
  const [t, _] = useTranslation('default');

  const [modalOpen, setModalOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isProVersion, setIsProVersion] = useState(false);
  const [licenseKey, setLicenseKey] = useState('');
  const [isInvalidKey, setIsInvalidKey] = useState(false);

  //===================
  // Download Handler
  //===================
  const handleSubmit = (event) => {
    // const [t, i18n] = useTranslation("default");
  
    gtag_report_conversion();
    setIsDownloading(true); // 다운로드 시작 상태 설정
    setProgress(0); // 진행률 초기화

    let data = {};
    if (isProVersion === true) {
      data = {
        license_key: licenseKey,
      };
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Content-Disposition": "attachment",
      },
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setIsInvalidKey(false);
        setModalOpen(false);
        setProgress(percentCompleted);
      },
    };

    const postUrl = isProVersion
      ? `${process.env.REACT_APP_API_ROOT}/download`
      : `${process.env.REACT_APP_API_ROOT}/download/community`;

    axios
      .post(
        postUrl,
        data,
        config
      )
      .then((response) => {
        if (response.status == 204) {
          // setModalOpen(true);
          // alert('Invalid license key.');
          setIsInvalidKey(true);
          return;
        }

        const filename =
          response.headers["content-disposition"].split(" filename=")[1];
        const blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        const fileObjectUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = fileObjectUrl;
        link.style.display = "none";
        link.download = filename;

        // Add link on body and force trigger click event
        document.body.appendChild(link);
        link.click();
        link.remove();

        window.URL.revokeObjectURL(fileObjectUrl);
      })
      .catch((error) => {
        alert(error.message);
        navigate("/error");
      })
      .finally(() => {
        setIsDownloading(false); // 다운로드 완료 상태로 변경
      });
  };

  //=====================
  // Page Component
  //=====================
  return (
    <div
      className="containerBox"
      style={{
        display: "flex",
        flexDirection: 'column',
        flexWrap: "wrap",
        alignItems: 'center',
        // margin: "100px 0px 0px 0px",
      }}
    >
      {/*
      <CommunityVersionDownload />
      <CodeActivation />
      */}
      <H1>{t('download.btn_download')}</H1>

      <DownloadTable
        disabled={isDownloading}
        onClickDownload={() => {
          handleSubmit(null);
        }}
        onClickSubmit={() => {
          // Set as pro version.
          setIsProVersion(true);
          setModalOpen(true);
        }}
      />
      <ProgressBar className="download-progress-bar"
        percentage={progress}
        style={{ width: '100%' }}
      />
      <BaseModal
        open={modalOpen}
        onDismiss={() => {
          setModalOpen(false);
          setIsInvalidKey(false); // Reset invalid key flag.
          // Set as community version.
          setIsProVersion(false);
        }}
      >
        <div className="license-modal">
          <h2>{t("download.head_title")}</h2>
          <BaseTextInput
            placeholder={t("download.placeholder")}
            onChange={(evt) => { setLicenseKey(evt.target.value); }}
          />
          <p id="inValid" style={{color:"red", visibility:isInvalidKey?"visible":"hidden"}}>
            Invalid license key
          </p>
          <BaseButton className="submit-button"
            onClick={() => {
              handleSubmit(null);
            }}
          >
            {t("download.btn_txt")}
          </BaseButton>
        </div>
      </BaseModal>
    </div>
  );
}

export default Download;
