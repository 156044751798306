import React from 'react'

import classNames from 'classnames'

import './index.scss'

interface H1Props extends React.HTMLProps<HTMLHeadingElement> {
}

const H1 = (props: H1Props) => {
  return (
    <h1 className={classNames('blurtle-h1', props.className)}
      style={props.style}
    >
      {props.children}
    </h1>
  );
}

interface H2Props extends React.HTMLProps<HTMLHeadingElement> {
}

const H2 = (props: H2Props) => {
  return (
    <h2 className={classNames('blurtle-h2', props.className)}
      style={props.style}
    >
      {props.children}
    </h2>
  );
}

export { H1, H2 }
