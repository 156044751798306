import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { StyledNavItem, StyledNavLink } from "../styles/HeaderStyle";

import { useTranslation } from "react-i18next";

import "../css/Header.css";
import { useIsMobile, useIsWindowsDesktop } from '../hooks';

import Logo from "../assets/FAVICON.svg";

// props:
// open - boolean.
// onClick - function.
const HamburgerMenuButton = (props) => {
  return (
    <button
      className="hamburger-menu-button"
      onClick={() => {
        if (props.onClick === undefined) {
          return;
        }
        props.onClick();
      }}
    >
      {!props.open ? (
        <>
          <div className="bun"></div>
          <div className="bun"></div>
          <div className="bun"></div>
        </>
      ) : (
        <>
          <div
            className="cross-bar"
            style={{
              transform: "rotate(45deg)",
            }}
          ></div>
          <div
            className="cross-bar"
            style={{
              transform: "rotate(135deg)",
            }}
          ></div>
        </>
      )}
    </button>
  );
};

function HeaderMobile() {
  const [t, i18n] = useTranslation("default");
  const [menuOpen, setMenuOpen] = useState(false);
  const isWindowsDesktop = useIsWindowsDesktop();

  return (
    <header id="top-navbar-wrap" style={{ display: "block" }}>
      <Nav
        className=""
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Navbar.Brand href="/">
          <img src={Logo} alt="Blurtle logo" width="53" height="53" />
          Blurtle
        </Navbar.Brand>
        <div style={{ marginLeft: "auto" }}>
          <NavDropdown
            title={t("lang_name_short")}
            color="#FFFFFF"
            style={{ fontSize: "12px" }}
          >
            <NavDropdown.Item
              onClick={
                () =>
                  i18n.changeLanguage(
                    "ko"
                  ) /*.then(() => {window.location.reload()})*/
              }
              style={{ fontSize: "12px" }}
            >
              Korean
            </NavDropdown.Item>
            <NavDropdown.Item
              onClick={
                () =>
                  i18n.changeLanguage(
                    "en"
                  ) /*.then(() => {window.location.reload()})*/
              }
              style={{ fontSize: "12px" }}
            >
              English
            </NavDropdown.Item>
          </NavDropdown>
        </div>
        <HamburgerMenuButton
          open={menuOpen}
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        />
      </Nav>
      {menuOpen && (
        <div className="hamburger-menu">
          <a className="menu-item" href="/manual">
            {t("header.how_to_use")}
          </a>
          <a className="menu-item" href="/faq">
            {t("header.faq")}
          </a>
          <a className="menu-item" href="/order">
            {t("header.buy_now")}
          </a>
          { isWindowsDesktop&&
          <a className="menu-item" href="/download">
            {t("header.download")}
          </a>
          }
        </div>
      )}
    </header>
  );
}

function HeaderPC() {
  const [t, i18n] = useTranslation("default");
  const isWindowsDesktop = useIsWindowsDesktop()

  return (
    <header id="top-navbar-wrap">
      <Navbar>
        <Navbar.Brand href="/">
          <img src={Logo} alt="Blurtle logo" width="53" height="53" />
          Blurtle
        </Navbar.Brand>

        <Nav variant="pills">
          {/* Future feature
                        <Nav.Item>
                            <Nav.Link className='default-nav-link' href='/feature'>Feature</Nav.Link>
                        </Nav.Item>
                        */}
          <Nav.Item>
            <Nav.Link className="default-nav-link" href="/manual">
              {t("header.how_to_use")}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="default-nav-link" href="/faq">
              {t("header.faq")}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="default-nav-link" href="/order">
              {t("header.buy_now")}
            </Nav.Link>
          </Nav.Item>
          {isWindowsDesktop&&
          <Nav.Item>
            <Nav.Link className="default-nav-link" href="/download">
              {t("header.download")}
            </Nav.Link>
          </Nav.Item>
          }
          {/*
                    <StyledNavItem>
                        <StyledNavLink href='/order'>{t("header.buy_now")}</StyledNavLink>
                    </StyledNavItem>
                    <StyledNavItem>
                        <StyledNavLink href='/download'>{t("header.download")}</StyledNavLink>
                    </StyledNavItem>
                    */}
          <NavDropdown title={t("lang_name")} color="#FFFFFF">
            <NavDropdown.Item
              onClick={
                () =>
                  i18n.changeLanguage(
                    "ko"
                  ) /*.then(() => {window.location.reload()})*/
              }
            >
              Korean
            </NavDropdown.Item>
            <NavDropdown.Item
              onClick={
                () =>
                  i18n.changeLanguage(
                    "en"
                  ) /*.then(() => {window.location.reload()})*/
              }
            >
              English
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar>
    </header>
  );
}

const Header = () => {
  const isMobile = useIsMobile();
  return isMobile ? <HeaderMobile /> : <HeaderPC />;
}

export default Header;
