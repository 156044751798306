import React from 'react'

import classNames from 'classnames'

import './BaseButton.scss'

interface BaseButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  asLink: boolean;
}

const BaseButton = (props: BaseButtonProps) => {
  return (
    <button className={classNames('base-button', props.className)}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}

export default BaseButton
