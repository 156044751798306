import { useState, useEffect } from 'react';

function useIsMobile(breakpoint = 810) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < breakpoint);

  useEffect(() => {
    const resizeHandler = () => setIsMobile(window.innerWidth < breakpoint);

    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, [breakpoint]);

  return isMobile;
}

export default useIsMobile;