import React from "react";
import {
    // IntroSection,
    // CatalogSectionFirst,
    // CatalogSectionSecond,
    // CatalogSectionThird,
    // CatalogSectionFourth,
    // LovedByPeople
    UserManual,
    VideoGuide
} from '../components/ManualSection';
import { ContainerBox, ContainerInnerBox } from "../styles/ManualPageStyle"
import { useTranslation } from "react-i18next";


function Manual() { 
    const [t, i18n] = useTranslation('default', { useSuspense: false });

    const url = i18n.language.toLowerCase() === 'ko' ? "https://doc.clickup.com/25605997/p/h/rddvd-5718/0837c900714dd61"
        : "https://doc.clickup.com/25605997/p/h/rddvd-5738/17716369061cd3c"

    return (
        <div style={{overflow: "hidden"}}>
        <iframe src={url}
        style={{
            border: "0px",
            width: "100%",
            height: "100vh",
            scrolling: "no"
        }}
        allowFullScreen>
        </iframe>
        </div>
    )
}

export default Manual;