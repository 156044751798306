import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  BackgroundNeon,
  CatalogBackground1,
  SloganH1,
} from "../styles/HomeSectionStyle";
import { useTranslation, Trans } from "react-i18next";
import { LinearProgress, Typography } from "@mui/material";

import "../css/HomeSection.scss";
import { useIsMobile, useIsWindowsDesktop } from "../hooks";

import BaseButton from "../components/BaseButton";
import appScreen from "../assets/HomeSection/HomePage_01.png";
import AppScreenKR from "../assets/HomeSection/HomePage_01_KR.png";
import whatIsBlurtle from "../assets/HomeSection/What_is_Blurtle.png";
import whatIsBlurtleKR from "../assets/HomeSection/What_is_Blurtle_KR.png";
import FeatureFABImg from "../assets/HomeSection/HomePage_02.jpg";
import FeatureEditableImg from "../assets/HomeSection/Feature_Editable.png";
import ClockIcon from "../assets/HomeSection/clock.png";
import BlurIcon from "../assets/HomeSection/blur.png";
import AccuracyIcon from "../assets/HomeSection/accuracy.png";
import AddIcon from "../assets/HomeSection/add.png";
import EditIcon from "../assets/HomeSection/edit.png";
import DeleteIcon from "../assets/HomeSection/delete.png";
import axios from "axios";
import gtag_report_conversion from "../lib/ConversionTracking";

class ApplicationMainImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { dimensions: {} };
    this.onImgLoad = this.onImgLoad.bind(this);
  }

  onImgLoad({ target: img }) {
    this.setState({
      dimensions: { height: img.offsetHeight, width: img.offsetWidth },
    });
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { src } = this.props;
    const width = this.state.dimensions.width + 200 + "px";
    const height = this.state.dimensions.height + 200 + "px";

    return (
      <div className={"ApplicationMainImage"}>
        <img onLoad={this.onImgLoad} src={src} />
        {/* <BackgroundNeon width={width} height={height}/>
                <BackgroundNeon degree='180' width={width} height={height}/> */}
      </div>
    );
  }
}

// description: The description text.
// return: The removed br description text
const descriptionMobile = (description) => {
  //DEBUG
  console.log(description);
  const updatedDescription = description
    .replace(/(<br\s*\/?>\s*){2,}|\.(<br\s*\/?>)/gi, (match) =>
      match.includes(".") ? ".<DR><DR>" : "<DR><DR>"
    )
    .replace(/(<br\s*\/?>|\\n)/gi, " ")
    .replace(/<DR\s*\/?>/gi, "<br>");
  console.log(updatedDescription);
  return updatedDescription;
};

// props:
// title: The title text.
// description: The description text.
const AppSimpleDescription = (props) => {
  const isMobile = useIsMobile();
  const [description, setDescription] = useState(props.description);

  /*
  useEffect(() => {
    if (isMobile) {
      // <br><br>은 그대로 두고, 나머지 <br>은 공백으로 대체
      const updatedDescription = descriptionMobile(props.description);
      setDescription(updatedDescription);
    } else {
      setDescription(props.description);
    }
  }, [isMobile, props.description]);
  */
  useEffect(() => {
    setDescription(props.description);
  }, [props.description]); // props.description이 바뀔 때마다 description 상태 업데이트

  return (
    <div className="app-simple-description">
      <div className="text-wrapper">
        <p
          className="text-title"
          dangerouslySetInnerHTML={{ __html: props.title }}
        ></p>
        {Array.isArray(description) ? (
          description.map((desc, index) => (
            <p
              key={index}
              className="text-description"
              dangerouslySetInnerHTML={{ __html: desc }}
            ></p>
          ))
        ) : (
          <p
            className="text-description"
            dangerouslySetInnerHTML={{ __html: description }}
          ></p>
        )}
      </div>
    </div>
  );
};

// props:
// src: img tag image src.
// children: ...
// gravity: 'left' | 'right'. default is 'left'.
const AppImageWithDescription = (props) => {
  const gravity = props.gravity === undefined ? "left" : props.gravity;
  const className = `app-image-with-description ${
    gravity === "right" ? "app-image-with-description--gravity-right" : ""
  }`;

  return (
    <div className={className}>
      <img className="image" src={props.src} />
      <div className="description">{props.children}</div>
    </div>
  );
};

function IntroSection() {
  const [t, i18n] = useTranslation("default");
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const isWindowsDesktop = useIsWindowsDesktop();

  const handleSubmit = (event) => {
    if (!isWindowsDesktop) {
      alert(t("home.alert"));
    } else navigate("/download");
  };

  const localizedImage =
    i18n.language.toLowerCase() === "ko" ? AppScreenKR : appScreen;

  return (
    <>
      <div className={"intro-section"}>
        {/* FUTURE
                <ReleaseStatusLink/>
                */}
        <h1>{t("home.slogan.slogan_1")}</h1>
        <h1 style={{ color: "#267DCC" }}>{t("home.slogan.slogan_2")}</h1>
        <BaseButton className={"get-blurtle-button"} onClick={handleSubmit}>
          {t("home.get_blurtle_now")}
        </BaseButton>
        {/* <p className={"sub-slogan"}>{t("home.explain")}</p> */}

        <div className={"scroll-mouse"}>
          <a href="#">
            <span></span>
          </a>
          <h5>scroll</h5>
        </div>
      </div>
      <div className="intro-introduce">
        <AppImageWithDescription src={localizedImage} gravity="left">
          <AppSimpleDescription
            title={t("home.catalogue_intro.title")}
            description={t("home.explain", { returnObjects: true })}
          />
        </AppImageWithDescription>
        {/* FUTURE
                <PoweredBy/>
                */}
      </div>
    </>
  );
}

function CatalogueIntro() {
  const [t, i18n] = useTranslation("default");

  const localizedImage =
    i18n.language.toLowerCase() === "ko" ? whatIsBlurtleKR : whatIsBlurtle;

  return (
    <div className={"catalog-introduce"}>
      <AppImageWithDescription src={localizedImage} gravity="right">
        <AppSimpleDescription
          title={t("home.catalogue_intro.what_is_blurtle")}
          description={t("home.catalogue_intro.description", {
            returnObjects: true,
          })}
        />
      </AppImageWithDescription>
    </div>
  );
}

function CatalogueFeature() {
  const [t, i18n] = useTranslation("default");
  const isMobile = useIsMobile();
  const [description1, setDescription1] = useState("");
  const [description2, setDescription2] = useState("");
  const [description3, setDescription3] = useState("");

  useEffect(() => {
    var description_1 = t("default:home.catalogue_feature.description_1");
    var description_2 = t("default:home.catalogue_feature.description_2");
    /*
    var description_3 = t("default:home.catalogue_feature.description_3");

    if (isMobile) {
      setDescription1(descriptionMobile(description_1));
      setDescription2(descriptionMobile(description_2));
      setDescription3(descriptionMobile(description_3));
    } else {
      setDescription1(description_1);
      setDescription2(description_2);
      setDescription3(description_3);
    }
      */
    var description_3 = t("default:home.catalogue_feature.description_3", {
      returnObjects: true,
    });

    setDescription1(description_1);
    setDescription2(description_2);
    setDescription3(description_3);
  }, [isMobile, t]);

  return (
    <>
      <div className={"catalog-introduce"}>
        <AppImageWithDescription src={FeatureFABImg}>
          <div className={"catalog-section-row"}>
            <div className={"catalog-section-description"}>
              <div style={{ display: "flex", gap: "10px" }}>
                <img className="icon" src={ClockIcon} />
                <p className="text-title">
                  <Trans i18nKey={"default:home.catalogue_feature.title_1"} />
                </p>
              </div>
              <div style={{ display: "grid", gap: "10px" }}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <img className="icon" src={BlurIcon} />

                  <p
                    className="text-description"
                    dangerouslySetInnerHTML={{ __html: description1 }}
                  ></p>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <img className="icon" src={AccuracyIcon} />
                  <p
                    className="text-description"
                    dangerouslySetInnerHTML={{ __html: description2 }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </AppImageWithDescription>
      </div>
      <div className={"catalog-introduce"}>
        <AppImageWithDescription src={FeatureEditableImg} gravity="right">
          <div className={"catalog-section-row"}>
            <div className={"catalog-section-description"}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  flexDirection: "row",
                  paddingTop: "20px",
                }}
              >
                <img className="icon" src={DeleteIcon} />
                <img className="icon" src={EditIcon} />
                <img className="icon" src={AddIcon} />
              </div>
              <p className="text-title">
                <Trans i18nKey={"default:home.catalogue_feature.title_2"} />
              </p>
              {Array.isArray(description3) ? (
                description3.map((desc, index) => (
                  <p
                    key={index}
                    className="text-description"
                    dangerouslySetInnerHTML={{ __html: desc }}
                  ></p>
                ))
              ) : (
                <p
                  className="text-description"
                  dangerouslySetInnerHTML={{ __html: description3 }}
                ></p>
              )}
            </div>
          </div>
        </AppImageWithDescription>
      </div>
    </>
  );
}

export { IntroSection, CatalogueIntro, CatalogueFeature };
