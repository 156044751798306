import React from 'react'

import './BaseTextInput.scss'

const BaseTextInput = (props) => {
  const inputClassName = `input-field ${props.className}`;

  return (
    <div className="base-text-input">
      <div className="label">
        {props.label}
      </div>
      <input className={inputClassName}
        type={props.type}
        id={props.id}
        name={props.id}
        value={props.value}
        placeholder={props.placeholder}
        maxLength={props.maxLength}
        tabIndex={props.tabIndex}
        onChange={props.onChange}
      />
    </div>
  );
}

export default BaseTextInput
