import React from 'react'
import { SocialIcon } from 'react-social-icons';
import { Link } from "react-router-dom";
import { Term } from '../pages/TermPage';

import { FieldAndValue, FieldAndValuesBox, FieldWhite, Value, FieldGrey, FieldAnchor, TermsAndPolicy } from "../styles/FooterStyle";
import '../css/Footer.css';
import { useTranslation, Trans } from "react-i18next";


function Footer() {
    const [t, i18n] = useTranslation('default');

    return (
        <footer className={'footer'}>
            <div className={'footer-inner'}>
                <div className={"terms-and-policy-with-sns"}>
                    <TermsAndPolicy>
                        <Link to='/term'>
                            <p >{t("footer.term")}</p>
                        </Link>
                        <p style={{color: "white"}}>&nbsp;&nbsp;|&nbsp;&nbsp;</p>
                        <p>{" "}</p>
                        <Link to='/policy'>
                            <p>{t("footer.policy")}</p>
                        </Link>
                    </TermsAndPolicy>
                    <div className={'socialBox'}>
                        <SocialIcon network='facebook' className='social-icon'/>
                        <SocialIcon network='twitter' className='social-icon'/>
                        <SocialIcon network='instagram' className='social-icon'/>
                        <SocialIcon network='youtube' className='social-icon'/>
                    </div>
                </div>
                <p className={"company-name"}>{t("footer.company_name")}</p>
                <div className={"information"}>
                <FieldAndValue>
                    <FieldWhite>{t("footer.ceo")}</FieldWhite>
                    <Value>{t("footer.ceo_name")}</Value>
                </FieldAndValue>
                <FieldAndValuesBox>
                    <FieldAndValue>
                        <FieldWhite>{t("footer.business_registration_number_field")}</FieldWhite>
                        <Value>{t("footer.business_registration_number_value")}</Value>
                    </FieldAndValue>
                    {
                        i18n.language.toLowerCase() === "ko" ? (
                            <>
                                <FieldAnchor href={"https://www.ftc.go.kr/bizCommPop.do?wrkr_no=6698702579 "}>{" | " + t("footer.check_business_information")}</FieldAnchor>
                                <FieldAndValue>
                                    <FieldWhite>{" | " + t("footer.telecommunication_sales_business_report_number_field")}</FieldWhite>
                                    <Value>{t("footer.telecommunication_sales_business_report_number_value")}</Value>
                                </FieldAndValue>
                                <FieldAndValue>
                                    <FieldWhite>{" | " + t("footer.address_field")}</FieldWhite>
                                    <Value>{t("footer.address_value")}</Value>
                                </FieldAndValue>
                            </>
                        ) : <></>
                    }
                </FieldAndValuesBox>
                <FieldAndValuesBox>
                    {
                        i18n.language.toLowerCase() === "ko" ? (
                            <FieldAndValue>
                                <FieldGrey>{t("footer.phone_field")}</FieldGrey>
                                <Value>{t("footer.phone_value") + " | "}</Value>
                            </FieldAndValue>
                        ) : <></>
                    }
                    <FieldAndValue>
                        <FieldGrey>{t("footer.mail_field")}</FieldGrey>
                        <Value>{t("footer.mail_value")}</Value>
                    </FieldAndValue>
                    <FieldAndValue>
                        <FieldGrey>{" | " + t("footer.dpo_field")}</FieldGrey>
                        <Value>{t("footer.dpo_value")}</Value>
                    </FieldAndValue>
                </FieldAndValuesBox>
                <FieldGrey style={{lineHeight: "100%", paddingTop: "7px"}}>{t("footer.customer_service_office_hour")}</FieldGrey>
                <FieldAndValuesBox>
                    <FieldAndValue>
                        <Value>{t("footer.weekday_field")}</Value>
                        <Value>{t("footer.weekday_value")}</Value>
                    </FieldAndValue>
                    {
                        i18n.language.toLowerCase() == "ko" ? (
                            <>
                                <FieldAndValue>
                                    <Value>{" | " + t("footer.break_time_filed")}</Value>
                                    <Value>{t("footer.break_time_value")}</Value>
                                </FieldAndValue>
                                <Value>{" | " + t("footer.closed_on_weekend_and_holidays")}</Value>
                            </>
                        ) : <></>
                    }
                </FieldAndValuesBox>
                </div>
                <p className={"copyright"}>{t("footer.copyright")}</p>
            </div>
        </footer>
    );
}

export default Footer;