import React from "react";
import {
    // IntroSection,
    // Inquiry,
    SupportMain,
} from '../components/SupportSection';


function Support() {
    return (
        // <div>
        //     <IntroSection/>
        //     <Inquiry/>
        // </div>
        <SupportMain/>
    )
}

export default Support;