import React from "react"
import {Background, LoadingText} from '../styles/LoadingPageComponentsStyles';
import Spinner from '../assets/LoadingAnimation.gif';


function Loading() {
    return (
        <Background>
            <LoadingText>Loading</LoadingText>
            <img src={Spinner} alt="Loading" width="5%" />
        </Background>
    )
}

export default Loading;