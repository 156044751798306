// ConversionTracking.js
const gtag_report_conversion = (url) => {
    const callback = () => {
        if (typeof(url) !== 'undefined') {
            window.location = url; // URL이 정의되어 있으면 해당 페이지로 이동
        }
    };

    window.gtag('event', 'conversion', {
        'send_to': 'AW-11070386998/y6U6CMvq3NkZELbm4p4p', // 전환 ID
        'value': 1.0, // 전환 값
        'currency': 'KRW', // 통화
        'transaction_id': '', // 거래 ID (필요 시)
        'event_callback': callback // 전환 후 호출될 콜백 함수
    });
    return false; // 기본 이벤트 방지
};

export default gtag_report_conversion; // 모듈로 내보내기
