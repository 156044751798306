import { style } from "@mui/system";
import styled, { css } from "styled-components";

export const Subtitle = styled.h2`
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.02em;

  color: #27272a;
`;

export const Intro = styled.div`
  font-style: normal;
  align-items: center;
`;

export const CardBox = styled.div`
  display: grid;
  font-style: normal;
  align-items: center;
  justify-items: center;
  padding: 50px 200px 50px 200px;
  grid-template-columns: auto auto auto;
  gap: 10px;
`;

export const StyledCard = styled.div`
  align-content: normal;
  padding: 40px 40px 40px 40px;

  max-width: 1000px;
  max-height: 1000px;

  background: #ffffff;
  outline-style: solid;
  outline-color: #aaaaaa30;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
`;

export const InquiryBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-style: normal;
  padding: 50px 200px 50px 200px;
  row-gap: 50px;
  align-items: center;
  justify-items: center;

  > form {
    display: flex;
    font-size: 28px;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 30px;
  }
`;

export const InquiryRow = styled.div`
  display: flex;
  flex-direction: column;

  > input {
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 20px;
    min-height: 70px;
  }

  > textarea {
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 20px;
    min-height: 500px;
  }
`;

export const InquiryCol = styled.div`
  display: grid;
  grid-template-columns: 3fr 7fr;
  column-gap: 20px;
  place-items: start;

  > * {
    width: 100%;
  }

  > input {
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 20px;
  }
`;

export const StyledButton = styled.input`
  font-size: 28px;
  max-width: 300px;
  max-height: 150px;
  align-self: end;
  justify-content: center;
  align-items: center;
  padding: 0px;
  padding: 10px 30px 10px 30px;
  background: #1f93c6;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  color: white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 22px;
  border-color: transparent;
`;

export const ContainerBox = styled.main`
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(208, 244, 255, 0) 0%,
    #a6e4f6 99.36%
  );
`;

export const ContainerInnerBox = styled.div`
  max-width: 1140px;
  margin: 0 auto 100px;
  text-align: center;
`;

export const HeadTitle = styled.h1`
  padding: 100px 20px 30px 20px;
  font-style: normal;
  font-size: 36px;
  font-weight: 900;
  color: #00bef2;
`;

export const NotiBox = styled.div`
  padding: 20px 30px;
  outline-style: solid;
  outline-color: #aaaaaa30;
  box-shadow: 8px 8px 24px rgba(0, 0, 0, 0.25);
  background: rgb(255 255 255 / 49%);
  border-radius: 20px;
  text-align: center;
  color: #484848;

  h2 {
    margin-bottom: 0.9rem;
    font-size: 16px;
    font-weight: 700;
  }

  p {
    font-size: 14px;
    font-weight: 400;
  }
`;

export const ContactBox = styled.div`
  margin: 80px auto 100px;
  padding: 20px;

  h2 {
    word-break: keep-all;
    font-style: normal;
    font-size: min(5vw, 32px);
    font-weight: 700;
    color: #00bef2;
  }
`;

export const ButtonBox = styled.div`
  margin: 50px auto;

  a {
    padding: 11px 20px;
    font-size: min(4vw, 24px);
    line-height: 29px;
    text-align: center;
    font-weight: 700;
    color: #ffffff;
    background: #00bef2;
    border: 1px solid #00bef2;
    border-radius: 20px;
    text-decoration: none;
  }
`;

