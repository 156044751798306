import React from "react";
import {
    FeaturecardSection,
    IntroSection,
} from '../components/FeatureSection';


function Feature() {
    return (
        <div className={'Feature'}>
            <IntroSection/>
            <FeaturecardSection/>
        </div>
    )
}

export default Feature;