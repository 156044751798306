/* src/RouteChangeTracker.js */
import { useEffect, useState } from "react";
import ReactGA from "react-ga";
import {useLocation} from "react-router-dom";

/**
 * uri 변경 추적 컴포넌트
 * uri가 변경될 때마다 pageview 이벤트 전송
 */

const RouteChangeTracker = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    // 구글 애널리틱스 운영서버만 적용
    useEffect(() => {
        if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
            ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
            setInitialized(true);
        }
    }, []);

    // location 변경 감지시 pageview 이벤트 전송
    useEffect(() => {
        if (initialized) {
            ReactGA.set({ page: location.pathname });
            ReactGA.send("pageview");
        }
    }, [initialized, location]);
};

export default RouteChangeTracker;