import React from "react";
import { useTranslation, Trans } from "react-i18next";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";

import {
  CardBox,
  InquiryBox,
  InquiryCol,
  InquiryRow,
  Intro,
  StyledButton,
  StyledCard,
  Subtitle,
  ContainerBox,
  ContainerInnerBox,
  HeadTitle,
  NotiBox,
  ContactBox,
  ButtonBox,
} from "../styles/SupportSectionStyle";
import { Container } from "react-dom";

import BaseButton from '../components/BaseButton'

import '../css/Faq.scss'

const FAQ = ({ index }) => {
  const [t, i18n] = useTranslation("FAQ");
  const qna = t(`categories.${index}.qna`, { returnObjects: true });
  const [expanded, setExpanded] = React.useState(false);
  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <>
      {qna.map((item, key) => (
        <Accordion
          key={key}
          expanded={expanded}
          onChange={handleExpansion}
          slots={{ transition: Fade }}
          slotProps={{ transition: { timeout: 400 } }}
          sx={{
            "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
            "& .MuiAccordionDetails-root": {
              display: expanded ? "block" : "none",
            },
          }}
        >
          <AccordionSummary
            style={{ fontSize: "min(3.8vw, 20px)" }}
            expandIcon={<ExpandMoreIcon />}
            key={key}
          >
            {qna[key].q}
          </AccordionSummary>
          <AccordionDetails style={{ fontSize: "min(3.8vw, 20px)" }}>
            {qna[key].a}
          </AccordionDetails>
          {key == 3 && index == 0 ? (
            <ul>
              <li>{t("SoftwareSpec.0")}</li>
              <li>{t("SoftwareSpec.1")}</li>
              <li>{t("SoftwareSpec.2")}</li>
              <li>{t("SoftwareSpec.3")}</li>
              <li>{t("SoftwareSpec.4")}</li>
            </ul>
          ) : (
            <></>
          )}
        </Accordion>
      ))}
    </>
  );
};

function FAQParagraph() {
  const [t, i18n] = useTranslation("FAQ");
  const faqs = t("categories", { returnObjects: true });
  return (
    <>
      <h1
        style={{
          color: "#00BEF2",
          textAlign: "center",
          marginTop: "min(10vw, 100px)",
          marginBottom: "min(10vw, 100px)",
        }}
      >
        <strong>FAQ</strong>
      </h1>

      {faqs.map((item, i) => (
        <>
          <h1
            style={{
              color: "#00BEF2",
              fontSize: "min(5vw, 32px)",
              marginTop: "20px",
            }}
          >
            {item.title}
          </h1>
          <FAQ index={i} />
        </>
      ))}
    </>
  );
}

function ContactInfo() {
  const [t, i18n] = useTranslation("default");

  const oldReturn = (
    <ContactBox>
      <ContainerInnerBox>
        <NotiBox>
          <h2>{t("support.contact_info.title")}</h2>
          <p style={{ fontSize: "min(4vw, 28px)" }}>
            {t("support.contact_info.content")}
          </p>
        </NotiBox>
        <ContactBox>
          <h2>
            <Trans i18nKey={"default:support.contact_us.title"} />
          </h2>
          <ButtonBox>
            <address>
              <a href={t("support.contact_us.mailto")}>
                {t("support.contact_us.btn_txt")}
              </a>
            </address>
          </ButtonBox>
        </ContactBox>
      </ContainerInnerBox>
    </ContactBox>
  );

  return (
    <section className="contact-info">
      <h2 className="contact-info__title">
        <Trans i18nKey={'default:support.contact_us.title'} />
      </h2>
      <BaseButton
        className="contact-info__button"
      >
        <a className="mailto"
          href={t('support.contact_us.mailto')}
        >
          <h2 className="contact-us">
            {t('support.contact_us.btn_txt')}
          </h2>
          <div className="email">{t('support.contact_info.content')}</div>
        </a>
      </BaseButton>
    </section>
  );
}

export { FAQParagraph, ContactInfo };
