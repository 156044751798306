import React from 'react'

import classNames from 'classnames'

import './BaseTable.scss'

//====================
// Table Header Cell
//====================
interface HeaderCellProps {
  children: React.ReactNode;
}

const HeaderCell = (props: HeaderCellProps) => {
  return (
    <th className="base-table__header-cell">
      {props.children}
    </th>
  );
}

//==================
// Table Data Cell
//==================
interface DataCellProps {
  style: React.CSSProperties,
  children: React.ReactNode;
}

const DataCell = (props: DataCellProps) => {
  return (
    <td className="base-table__data-cell"
      style={props.style}
    >
      {props.children}
    </td>
  );
}

//=================
// Table row
//=================
interface RowProps {
  textAlign: 'left' | 'right' | 'center' | undefined;
  children: React.ReactElement<HeaderCellProps> | React.ReactElement<DataCellProps>;
}

const Row = (props: RowProps) => {
  const alignClass = (() => {
    if (props.textAlign === 'left') {
      return 'left';
    } else if (props.textAlign === 'right') {
      return 'right';
    } else if (props.textAlign === 'center') {
      return 'center';
    } else {
      return 'left';
    }
  })();

  return (
    <tr className={classNames('base-table__row', alignClass)}>
      {props.children}
    </tr>
  );
}

//======================
// Table Header Group
//======================
interface HeaderProps {
  children: React.ReactElement<RowProps>,
}

const Header = (props: HeaderProps) => {
  return (
    <thead className="base-table__header">
      {props.children}
    </thead>
  );
}

//======================
// Table Body Group
//======================
interface BodyProps {
  children: React.ReactElement<RowProps>,
}

const Body = (props: BodyProps) => {
  return (
    <tbody className="base-table__body">
      {props.children}
    </tbody>
  );
}

//=======================
// Table Component
//=======================
interface BaseTableProps {
  children: (React.ReactElement<HeaderProps> | React.ReactElement<BodyProps>)[];
}

const BaseTable = (props: BaseTableProps) => {
  return (
    <table className="base-table">
      {props.children}
    </table>
  );
}

BaseTable.HeaderCell = HeaderCell;
BaseTable.DataCell = DataCell;
BaseTable.Row = Row;
BaseTable.Header = Header;
BaseTable.Body = Body;

export default BaseTable
