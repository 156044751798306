import React from 'react'

import './TermsSection.scss'

// props:
// title: string.
// contents: string[].
const TermsSection = (props) => {
  return (
    <section className="terms-section"
    >
      <h2 className="title">{props.title}</h2>
      <ul className="items">
        {props.contents.map((content, idx) => {
          return (
            <li className="item"
              key={idx}
            >
              {content}
            </li>
          );
        })}
      </ul>
    </section>
  );
}

export default TermsSection
