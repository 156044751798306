import styled from "styled-components";
import { Nav } from "react-bootstrap";

//navbar style
export const StyledNavItem = styled(Nav.Item)`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 24px */

  letter-spacing: 0.005em;

  background: #00bbeee3;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  &:hover {
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    background: #00bbee;
  }

  &:active {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  }
`;

export const StyledNavLink = styled(Nav.Link)`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  color: #ffffff;
  border-radius: 10px;
  letter-spacing: 0.005em;

  &:hover {
    color: #ffffff;
  }
`;

